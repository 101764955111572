<template>
  <div class="dashboard-pop-edit-container">
    <a-modal
      :title="row.id?'编辑菜单':'新增菜单'"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel"
      width="800px"
    >

<a-form
        :form="form"
        :label-col="{ span: 10 }"
        :wrapper-col="{ span: 14 }"
        @submit="handleSubmit"
      >

      <a-row>
        <a-col :span="12">
          <a-form-item label="上级菜单">
          <a-tree-select
            v-decorator="formDecorators.pid"
            style="width: 100%"
            :tree-data="menuList"
            :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
            placeholder="请选择权限规则"
          >
          </a-tree-select>

        </a-form-item>

        </a-col>
        <a-col :span="12">
         <a-form-item label="菜单名称">
          <a-input
            v-decorator="formDecorators.title"
          />
        </a-form-item>
      </a-col>
      </a-row>

      <a-row>
        <a-col :span="12">
        <a-form-item label="路由地址">
          <a-input
            v-decorator="formDecorators.router"
          />
        </a-form-item> 
      </a-col>
        <a-col :span="12">
         <a-form-item label="权限标识">
          <a-input
            v-decorator="formDecorators.alias"
          />
        </a-form-item>
        </a-col>
      </a-row>

      <a-row>
        <a-col :span="12">
         <a-form-item label="菜单类型">
          <a-radio-group v-decorator="formDecorators.type" button-style="solid" >
            <a-radio-button :value="1">
              菜单
            </a-radio-button>
            <a-radio-button :value="2">
              按钮
            </a-radio-button>
          </a-radio-group>
        </a-form-item>
        </a-col>
        <a-col :span="12">
         <a-form-item label="是否鉴权">
          <a-radio-group v-decorator="formDecorators.auth" button-style="solid" >
            <a-radio-button :value="1">
              是
            </a-radio-button>
            <a-radio-button :value="2">
              否
            </a-radio-button>
          </a-radio-group>
        </a-form-item>
      </a-col>
      </a-row>

      <a-row>
        <a-col :span="12">
        <a-form-item label="排序">
          <a-input
            type="number"
            v-decorator="formDecorators.sort"
            placeholder="数字越小越靠前"
          />
        </a-form-item>
        </a-col>
        <a-col :span="12">
         <a-form-item label="状态">
          <a-radio-group v-decorator="formDecorators.status" button-style="solid" >
            <a-radio-button value="normal">
              正常
            </a-radio-button>
            <a-radio-button value="close">
              关闭
            </a-radio-button>
          </a-radio-group>
        </a-form-item>
      </a-col>
      </a-row>

      <a-row>
        <a-col :span="12">
        <a-form-item label="备注信息">
          <a-input
            v-decorator="formDecorators.note"
            placeholder="备注信息"
          />
        </a-form-item>
        </a-col>
        <a-col :span="12">
        <a-form-item label="菜单图标">
          <a-input v-decorator="formDecorators.icons" placeholder="点击右侧按钮选择图标">
            <a-icon slot="addonAfter" type="setting" @click="iconVisible = true" />
          </a-input>
        </a-form-item>
        </a-col>
      </a-row>

      <a-row>
        <a-col :span="12">
        <a-form-item label="接口Api">
          <a-input
            v-decorator="formDecorators.api"
            placeholder="Api权限"
          />
        </a-form-item>
        </a-col>
        <a-col :span="12"></a-col>
      </a-row>


      </a-form>
    </a-modal>


    <a-modal title="编辑商品标签" :visible="iconVisible" width="80%"   @ok="handleOkIcon" @cancel="iconVisible=false" >
      <icon-selector @change="handleIconChange" />
    </a-modal>

  </div>
</template>

<script>

import IconSelector from '@/components/IconSelector'
export default {
  data() {
    return {
      confirmLoading: false,
      form: this.$form.createForm(this, { name: "coordinated" }),
      menuList:[],
      iconVisible:false
    };
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    row: {
      type: Object,
      default: ()=>{
        return {}
      },
    },
    menus: {
      type: Array,
      default:()=>{
        return []
      }
    },
  },
  components: {
    IconSelector
  },
  computed: {
    formDecorators() {
      let data = JSON.parse(JSON.stringify(this.row));

      return {
        pid: [
          "pid",
          {
            initialValue: (data && data.pid)||0,
            rules: [{ required: true, message: "请选择上级菜单" }],
            // validateTrigger: ["blur"],
          },
        ],
        title: [
          "title",
          {
            initialValue: data && data.title,
            rules: [{ required: true, message: "请填写菜单名称" }],
            validateTrigger: ["blur"],
          },
        ],
        alias: [
          "alias",
          {
            initialValue: data && data.alias,
          },
        ],
        router: [
          "router",
          {
            initialValue: data && data.router,
            rules: [{ required: true, message: "请填写路由地址" }],
            validateTrigger: ["blur"],
          },
        ],
        auth: [
          "auth",
          {
            initialValue: (data && data.auth)||1,
          },
        ],
        type: [
          "type",
          {
            initialValue: (data && data.type)||1,
          },
        ],
        status: [
          "status",
          {
            initialValue: (data && data.status)||'normal',
          },
        ],
        icons: [
          "icons",
          {
            initialValue: (data && data.icons)||'normal',
          },
        ],
        sort: [
          "sort",
          {
            initialValue: (data && data.sort)||0,
          },
        ],
        note: [
          "note",
          {
            initialValue: (data && data.note)||'',
          },
        ],
        api: [
          "api",
          {
            initialValue: (data && data.api)||'',
          },
        ]
      };
    },
  },
  created(){
    this.menuList= JSON.parse(JSON.stringify(this.menus));
    this.menuList.unshift({
      title:'顶级菜单',
      id:0,
      key:0,
      value:0
    })
  },
  mounted() {
    
  },
  filters:{

  },
  methods: {
    // 图标选择
    handleIconChange (icon) {
      this.icon = icon
      let fieldsValue = {
        icons: icon
      }
      this.form.setFieldsValue(fieldsValue)
    },

    handleOkIcon () {
      if (this.icon) {
        let fieldsValue = {
          icons: this.icon
        }
        this.form.setFieldsValue(fieldsValue)
        this.iconVisible = false
      } else {
        this.$message.warning('请选择图标')
      }
    },
    handleOk(){
      this.confirmLoading = true;
      this.form.validateFields((err, values) => {
        if (!err) {
          if(this.row.id){
            values.id=this.row.id
          }else{
            values.id=''
          }
          this.$api.post(`/v2/adminRules/add`, values)
          // this.$api.post(`/v2/adminRules/add`, {pid:0})
            .then((resp) => {
              if (resp.code == 200) {
                this.$message.success("设置成功");
                this.$emit("handleOk");
              } else {
                this.$message.error(resp.message);
              }
              this.confirmLoading = false;
            })
            .catch((e) => {
              this.confirmLoading = false;
              throw e;
            });
        }else{
          this.confirmLoading = false;
        }
        })
    },
    handleCancel(){
      this.$emit('handleCancel')
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log("Received values of form: ", values);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.dashboard-pop-edit-container {
}

.list {
  display: flex;
  margin-bottom: 30px;
  .list-left {
    display: flex;
    width: 350px;
    align-items: center;
  }
  .list-right {
    display: flex;
    align-items: center;
    margin-left: 50px;
  }
}

.label {
  width: 100px;
  font-size: 20px;
  color: #333333;
  display: flex;
  justify-content: space-between;
}
.value {
  font-size: 20px;
  color: #199aec;
  display: flex;
}
.l-height{
  line-height: 30px;
}
@media screen and(max-width:750px) {
  
.list {
  display: block;
  margin-bottom: 0px;
  .list-left {
    display: flex;
    width: 350px;
    align-items: center;
    margin-bottom: 15px;
  }
  .list-right {
    display: flex;
    align-items: center;
    margin-left: 0px;
    margin-bottom: 15px;
  }
}

.label {
  width: 100px;
  font-size: 16px;
  color: #333333;
  display: flex;
  justify-content: space-between;
}
.value {
  font-size: 16px;
  color: #199aec;
  display: flex;
}
.l-height{
  line-height: 30px;
}
}

</style>
