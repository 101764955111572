const columns = [
  {
    title: 'id',
    key: 'id',
    dataIndex: 'id'
  },
  {
    title: '菜单名称',
    key: 'title',
    dataIndex: 'title'
  },
  {
    title: '路由地址',
    key: 'router',
    dataIndex: 'router'
  },
  {
    title: '权限标识',
    key: 'alias',
    dataIndex: 'alias'
  },
  {
    title: '是否鉴权',
    key: 'auth',
    scopedSlots: { customRender: "auth" }
  },
  {
    title: '类型',
    key: 'type',
    scopedSlots: { customRender: "type" }
  },
  {
    title: '状态',
    key: 'status',
    scopedSlots: { customRender: "status" }
  },
  {
    title: '排序',
    key: 'sort',
    dataIndex: 'sort'
  },
  {
    title: '创建时间',
    key: 'created_at',
    dataIndex: 'created_at'
  },
  {
      title: "操作",
      key: "action",
      width: '100px',
      scopedSlots: { customRender: "action" },
  },
];

export default columns;
