<template>
    <div class="dashboard-table-card-container ">
      <le-card width="100%" style="padding-bottom:20px" >
        <template>
          <div class="container">
            <div class="card-title" style="padding-top: 20px;">菜单管理</div>
            <le-button txt="新增" class="le-button" @btnClick="addMenu()" v-if="isAdd" />
            <div class="table-box">
                <a-table 
                :columns="columns" 
                :data-source="dataSource"
                :loading="loading"
                size="middle" >

                <span slot="auth" slot-scope="row">
                    {{row.auth==1?'是':'否'}}
                </span>
                <div slot="type" slot-scope="row" >
                    <div>{{row.type==1?'菜单':'按钮' }}</div>
                </div>
                <div slot="status" slot-scope="row" >
                  <a-switch :checked="row.status=='normal'" :loading="row.sLoading"  @change="row.sLoading=true;onChange(row)" />
                </div>

                  <span slot="action" style="display: flex;justify-content:space-between;width: 100px;" slot-scope="row">
                    <le-button txt="编辑" width="40px" height="30px" fontSize="14px" @btnClick="clickEdit(row)" v-if="isAdd" />
                    <a-popconfirm title="确定删除吗？" ok-text="确定" cancel-text="取消"   @confirm="toDelete(row)"  v-if="isDelete"  >
                      <le-button background="linear-gradient(-10deg, #FD8A8F, #FA6A9D)" txt="删除" width="40px" height="30px" fontSize="14px"/>
                    </a-popconfirm>
                  </span>
              </a-table>
            </div>
          </div>
        </template>
      </le-card>
      <popEdit v-if="visible" :visible="visible"  @handleOk="handleOk"  @handleCancel="visible=false" :row="editRow"  :menus="dataSource"  />
    </div>
</template>

<script>

import columns from './columns'
import popEdit from './popEdit'
import tool from '@/utils/tool'
// import {MixinList} from '@/common/mixin';
export default {
  components: {
    popEdit
  },
  data() {
    return {
      loading: false,
      checkLoading:false,
      columns,
      dataUrl:'v2/adminRules/sel',
      delUrl:'v2/adminRules/del',
      uid:'',
      editRow:{},
      dataSource:[],
      visible: false,
      visibleLog: false,
      editVisible: false,
      confirmLoading: false,
      isAdd: false,
      isDelete: false
    };
  },
  computed: {
  },
  activated(){
    this.getData()
  },
  mounted(){
    let btn=window.localStorage.getItem('whiteBtns')
    if(btn){
      let btns=btn.split(',');
      if(btns.includes('auth-menu-add')){
        this.isAdd = true;
      }
      if(btns.includes('auth-menu-delete')){
        this.isDelete = true;
      }
    }
    this.getData()
  },
  methods: {
    onChange(row){
      let status=row.status=='normal'?'close':'normal'
      let {id,pid,title,router,alias,type,sort,icons,auth,api,note}=row
      let json={id,pid,title,router,alias,type,sort,icons,auth,api,note,status}
    this.$api.post(`/v2/adminRules/add`, json)
    .then((resp) => {
      if (resp.code == 200) {
        this.$message.success("设置成功");
        this.getData()
      } else {
        this.$message.error(resp.message);
      }
    })
    .catch((e) => {
      throw e;
    });
    },
    async getData() {
        this.loading = true
        let resp=null
        resp = await this.$api.get(this.dataUrl)
        this.loading = false
        this.dataSource = [];
        if (resp.code == 200) {
          this.dataSource = this.handleList(resp.data)
          console.log(this.dataSource)
        } else this.$message.error(resp.msg)
      },

    handleList(list){
      for(let i=0;i<list.length;i++){
        list[i].sLoading=false
        if(list[i].children.length>0){
          this.handleList(list[i].children)
        }
      }
		  return list
    },
    clickEdit(row){
      this.editRow=row
      this.visible = true;
    },
    handleOk(){
      this.visible = false;
      this.getData()
    },
    showLog(uid){
      console.log('uid',uid)
      this.uid=uid
      this.visibleLog=true
    },
    //新增菜单
    addMenu(){
      this.visible=true
      this.editRow={}
    },
    toDelete(row){
      this.$api
        .post(this.delUrl,{id:row.id})
        .then((resp) => {
          if (resp.code == 200) {
            this.$message.success('操作成功');
            this.getData()
          } else {
            this.$message.error(resp.message);
          }
        })
        .catch((e) => {
          throw e;
        });
    }
  }
};
</script>

<style lang="less" scoped>
.dashboard-table-card-container {
.container{
  position: relative;
  .title{
    padding-top: 20px;
    margin-left: 30px;
    font-size: 30px;
    font-weight: bold;
    color: #333333;
    display: flex;
    align-items: flex-end;
    .num-box{
      font-size: 18px;
      margin-left: 11px;
      line-height: 32px;
    }
  }
  .le-button{
    position: absolute;
    top: 30px;
    right: 30px;
  }

  .table-box{
    margin: 40px 30px;
  }
}
}

.login{
  cursor: pointer;
  &:hover{
    color: #008DF0;
  }
}

.name-box{
  display: flex;
  .avatar{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
    background-color: #333333;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    img{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    }
  }
}

@media screen and(max-width:750px) {
 .dashboard-table-card-container {
.container{
  .title{
    padding-top: 10px;
    margin-left: 20px;
    font-size: 20px;
    .num-box{
      font-size: 12px;
      margin-left: 5px;
      line-height: 22px;
    }
  }
  .le-button{
    position: absolute;
    top: 10px;
    right: 20px;
    width: 50px!important;
  }

  .table-box{
    margin: 20px;
    overflow-x:scroll ;
  }
}
}
}

</style>
